import React from 'react';
import navLinks from '../../constants/NavSilexiaConnected';
import { API_GET } from '../../functions/apiRequest';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';  
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap'

window.fn = OwlCarousel;

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            articles: {},
            redirectUrl: ''
        }
        this.toggle = this.toggle.bind(this);
        this.redirectUrl = '';
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    redirect = (path) => {
        const overlay = document.querySelector('.loader--redirect');
        overlay.classList.add('isLoading');

        this.setState({
            redirectUrl: path
        })

        setTimeout(() => {
            window.location.href = path;
        }, 3000);
    }

    handleClick(event) {
        var elems = document.querySelectorAll(".childsubmenu");
        [].forEach.call(elems, function(el) {
          el.classList.remove("show");
        });
        this.toggle();
    }

    componentDidMount() {
        API_GET("articles").then(result => this.setState({articles: result}));
    }

    render() {
        return (
            <>
                <header className="site-header header-fixed">
                    {
                        <div id="header-wrap">
                            <div className="container">
                                <div className="row">
                                    {/*menu start*/}
                                    <div className="col d-flex align-items-center justify-content-between bg-white"> 
                                        <Link className="navbar-brand logo text-dark h2 mb-0" to="/"><img className="logo img-fluid" src={require(`../../assets/images/logo.svg`)} alt="Logo Silexia" width="120" height="40" /></Link>
                                        <Navbar className="navbar-expand-lg navbar-light ml-auto">
                                                <NavItem className="d-none d-sm-block d-lg-none">
                                                    <a className="btn btn-primary btn-small mt-0 mr-4" href="/profile">Mon diagnostic</a>
                                                </NavItem>

                                                <NavbarToggler onClick={this.toggle} />

                                                <Collapse isOpen={this.state.isOpen} className=" navbar-collapse" navbar>
                                                    <Nav className="ml-auto" navbar>
                                                        {navLinks.map((navLink, index) => (
                                                            (navLink.type && navLink.type === 'subMenu') ?
                                                                // Submenu link
                                                                <li className="nav-item nav-item-parent" key={index}>
                                                                    <a href={navLink.path} className="nav-link">{navLink.menu_title}</a>
                                                                    <div className="nav-item-submenu">
                                                                        <div className="container justify-content-center">
                                                                            <div className="nav-item-submenu-content">
                                                                                <p className="nav-item-submenu-title">
                                                                                    <strong>{navLink.menu_subtitle}</strong>
                                                                                </p>
                                                                                {navLink.child_routes && navLink.child_routes.map((subNavLink, index) => (
                                                                                    subNavLink.redirect_path ? 
                                                                                        <a href={subNavLink.path} onClick={() => this.redirect(subNavLink.redirect_path)} className="nav-link" key={index} dangerouslySetInnerHTML={{__html: subNavLink.menu_title}}></a>
                                                                                    : 
                                                                                        subNavLink.path.includes('://') ? 
                                                                                            <a className="nav-link" target="_blank" key={index} href={subNavLink.path} dangerouslySetInnerHTML={{__html: subNavLink.menu_title}}></a>
                                                                                        : 
                                                                                            <a className="nav-link" key={index} href={subNavLink.path} dangerouslySetInnerHTML={{__html: subNavLink.menu_title}}></a>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                :
                                                                <NavItem key={index}>
                                                                    { navLink.path.includes('://') ?
                                                                        // Extern link
                                                                        <NavLink onClick={this.toggle} target="_blank" href={navLink.path} rel="noopener" rel="noreferrer">{navLink.menu_title}</NavLink>
                                                                    : 
                                                                        // Basic link
                                                                        <NavLink onClick={this.toggle} href={navLink.path}> {navLink.menu_title}</NavLink>
                                                                    }
                                                                </NavItem>
                                                        ))}
                                                        <NavItem className="d-sm-none d-lg-block">
                                                            <div className="d-flex align-items-center h-100">
                                                                <Link onClick={this.toggle} className="btn btn-primary btn-small ml-lg-3" to="/profile">Mon diagnostic</Link>
                                                            </div>
                                                        </NavItem>
                                                    </Nav>
                                                </Collapse>
                                        </Navbar>
                                    </div>
                                    {/*menu end*/}
                                </div>
                            </div>
                        </div>
                    }

                    { this.state.articles ?
                        <OwlCarousel
                            className={`header-banner`}
                            dotData={false}
                            items={1}
                            autoplay={true}
                            margin={30}
                            dots={false}
                            nav={false}
                            loop={true}
                        >
                            { this.state.articles.length > 0 ? this.state.articles.map((item, i) => 
                                <div key={i} className="header-banner-item">
                                    <p><span>Actualités</span> : { item.title } <Link className="link link-primary" to={{pathname: `/blog/${item['id']}`, state: { items: item }}}>Lire l'article</Link></p>
                                </div>
                            ) : ''}
                        </OwlCarousel>
                    : '' }
                </header>
            
                <div className="loader-wrapper loader--redirect">
                    <svg className="loader loader--global" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 403.8 403.8">
                        <clipPath id="clip_1">
                            <path d="M200.9,93.5v47.3l-40.7,75.6L255,316c44.1-20.9,72.6-65.7,72.6-114.6c0-69.9-56.8-126.7-126.7-126.7v-27
                                c84.8,0,153.8,69,153.8,153.8c0,69.9-40.5,120.2-103,145.3c-0.7,0.3-1.4,0.5-2.1,0.5c-1.5,0-2.8-0.6-3.7-1.6L129.5,223.5
                                c-0.9-1-1.4-2.2-1.4-3.5c0-1,0.3-1.9,0.8-2.7l67.4-124.9c0.8-1.7,2.6-2.8,4.5-2.8L200.9,93.5z"/>
                        </clipPath>
                        <clipPath id="clip_2">
                            <path d="M200.9,93.5v47.3l40.7,75.6L146.7,316c-44.1-20.9-72.6-65.7-72.6-114.6c0-69.9,56.8-126.7,126.7-126.7v-27
                                c-84.8,0-153.8,69-153.8,153.8c0,69.9,40.5,120.2,103,145.3c0.7,0.3,1.4,0.5,2.1,0.5c1.5,0,2.8-0.6,3.7-1.6l116.3-121.9
                                c0.9-1,1.4-2.2,1.4-3.5c0-1-0.3-1.9-0.8-2.7L205.4,92.4c-0.8-1.7-2.6-2.8-4.5-2.8L200.9,93.5z"/>
                        </clipPath>
                        <g clip-path="url(#clip_1)">
                            <path style={{stroke: "#DF423C"}} className="line-red" d="m174.7 50.6c0 0 141.2-7.5 164.3 122.4 20 112.5-87 160-87 160l-106-114 67.6-123.9" />
                        </g>
                        <g clip-path="url(#clip_2)">
                            <path style={{stroke: "#203158"}} className="line-blue" d="m194.6 95.6l62.4 124.4-107 112.5c0 0-96.2-28.7-89-135.5 10.5-156.3 169.2-145 169.2-145" />
                        </g>
                    </svg>
                    <p>Vous allez être redirigé vers le site <span>{this.state.redirectUrl}</span> ...</p>
                </div>
            </>
        );
    }
}

export default Header;