import React, { useEffect } from 'react';

const Loader = () => {
    useEffect(() => {
        return () => {
            const loaderWrapper = document.querySelector('#loader-wrapper');
            if (loaderWrapper) {
                loaderWrapper.classList.add('loaded');
            }
            const body = document.querySelector('.body')
            if (body) {
                body.classList.add('loaded');
            }
        }
    }, []);

    return <></>;
}

export default Loader;