/**
 *  Main Menu Json
 */
export default [
    {
       "menu_title": "Nous...",
       "path": "/numerique",
       "type": "subMenu",
       "child_routes": [
          {
             "path": "/offres/acquisition-clients",
             "menu_title": "Entrons en relation avec vos <strong>cibles clients</strong>",
          },
          {
             "path": "/offres/relation-client",
             "menu_title": "Fluidifions votre <strong>relation-client</strong>",
          },
          {
             "path": "/offres/automatisation",
             "menu_title": "<strong>Automatisons vos tâches</strong> à faible valeur ajoutée",
          },
          {
             "path": "/offres/pilotage-entreprise",
             "menu_title": "Optimisons votre <strong>pilotage d'entreprise</strong>",
          },
          {
             "path": "/offres/protection-donnees",
             "menu_title": "Assurons la <strong>protection de vos données</strong>",
          },
          {
             "path": "https://outlook.office365.com/owa/calendar/Silexia@silexia.onmicrosoft.com/bookings/",
             "menu_title": "Réserver mon entretien innovation",
          },
       ]
    },
    {
       "menu_title": "Pour qui",
       "path": "/",
       "type": "subMenu",
       "child_routes": [
          {
             "path": "/numerique",
             "menu_title": "Entreprises, start-up et indépendants",
          },
          {
             "path": "#",
             "redirect_path": "https://silexia.legal/",
             "menu_title": "Avocats, cabinets et DPO",
          },
          {
             "path": "/associations",
             "menu_title": "Entreprises de l'ESS, ONG et associations",
          },
          {
             "path": "https://outlook.office365.com/owa/calendar/Silexia@silexia.onmicrosoft.com/bookings/",
             "menu_title": "Réserver mon entretien innovation",
          },
       ]
    },
    {
       "menu_title": "Ce que nous sommes",
       "path": "/",
       "type": "subMenu",
       "child_routes": [
          {
             "path": "/success-story",
             "menu_title": "Un bureau pour vos projets numériques",
          },
          {
             "path": "/success-story#valeurs",
             "menu_title": "Engagé pour un numérique éthique et responsable",
          },
          {
             "path": "/reseau-partenaires",
             "menu_title": "Un réseau de tech et d'experts made in Europe",
          },
          {
             "path": "https://outlook.office365.com/owa/calendar/Silexia@silexia.onmicrosoft.com/bookings/",
             "menu_title": "Réserver mon entretien innovation",
          },
       ]
    },
    {
       "menu_title": "Nos services en ligne",
       "path": "/",
       "type": "subMenu",
       "child_routes": [
          {
             "path": "/diagnostic",
             "menu_title": "Diagnostiquer votre organisation gratuitement",
          },
          {
             "path": "/generateur-mentions-legales",
             "menu_title": "Générer les mentions légales de votre site web",
          },
          {
             "path": "https://outlook.office365.com/owa/calendar/Silexia@silexia.onmicrosoft.com/bookings/",
             "menu_title": "Réserver mon entretien innovation",
          },
       ]
    },
   {
      "menu_title": "Mon compte",
      "path": "/",
      "type": "subMenu",
      "child_routes": [
         {
            "path": "/profile/details",
            "menu_title": "Mes informations",
         },
         {
            "path": "/profile/logout",
            "menu_title": "Se déconnecter",
         },
      ]
   }
]
