import React, { useEffect } from 'react';

const FirstLoader = () => {
    useEffect(() => {
        document.querySelector('.body').classList.add('loaded');
    }, []);

    return (
        <div className="loader-wrapper loader-wrapper--first">
            <svg className="loader loader--first" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1190.6 403.8">
                <g className="sigle">
                    <clipPath id="clip_4">
                        <path d="M236.1,105v44.1l38,70.5l-88.5,92.9c-41.1-19.5-67.7-61.2-67.7-106.9c0-65.2,53-118.2,118.2-118.2V62.2   c-79.1,0-143.4,64.3-143.4,143.4c0,65.1,37.7,112,96.1,135.4c0.6,0.3,1.3,0.4,2,0.4c1.4,0,2.6-0.6,3.5-1.5l108.4-113.7   c0.8-0.9,1.3-2,1.3-3.3c0-0.9-0.3-1.8-0.7-2.5l-62.9-116.5c-0.8-1.6-2.4-2.6-4.2-2.6L236.1,105z"/>
                    </clipPath>
                    <clipPath id="clip_3">
                        <path d="M236.1,105v44.1l-38,70.5l88.5,92.9c41.1-19.5,67.7-61.2,67.7-106.9c0-65.2-53-118.2-118.2-118.2V62.2   c79.1,0,143.4,64.3,143.4,143.4c0,65.1-37.7,112-96.1,135.4c-0.6,0.3-1.3,0.4-2,0.4c-1.4,0-2.6-0.6-3.5-1.5L169.5,226.2   c-0.8-0.9-1.3-2-1.3-3.3c0-0.9,0.3-1.8,0.7-2.5l62.9-116.5c0.8-1.6,2.4-2.6,4.2-2.6L236.1,105z"/>
                    </clipPath>
                    <g clip-path="url(#clip_4)">
                        <path style={{stroke: "#DF423C"}} className="line-red" d="m222.7 104.6l67.8 114.5-99.7 109.6c0 0-92.1-32.5-85.2-130.1 8.6-120.1 154.6-127.6 154.6-127.6" />
                    </g>
                    <g clip-path="url(#clip_3)">
                        <path style={{stroke: "#203158"}} className="line-blue" d="m209.6 71.4c0 0 130.4-2.1 154.2 105.5 23.9 108.5-78.8 152.1-78.8 152.1l-99-107 62-118.8" />
                    </g>
                </g>

                <g className="title">
                    <path d="M484.8,169.8c0-8.9-8.1-13.4-24.2-13.4c-15.8,0-23.7,4.4-23.7,13.4v4.4c0,6.5,6,10,10.6,11.6l38.9,13.5   c14.3,5,23.6,13.3,23.6,25.8v14.5c0,16.4-14.6,31.5-49.7,31.5c-35.4,0-49.4-15.1-49.4-31.5v-6.5c0-3.3,1.4-5.1,4.8-5.1h13.7   c3.6,0,4.8,1.8,4.8,5.1v5.4c0,8.9,9.4,13.4,26.1,13.4c16.4,0,26.1-4.4,26.1-13.4v-7c0-5.9-4.9-9.2-13.5-12.1L434,206   c-9.7-3.4-20.5-13.4-20.5-26.6v-9.6c0-16.4,12.2-32.3,47.3-32.3c34.9,0,47.3,15.9,47.3,32.3v6.2c0,3.6-1.2,5.1-5.1,5.1h-13.5   c-3.3,0-4.8-1.5-4.8-5.1V169.8z"/>
                    <path d="M569.3,115.9c0,3.6-1.4,5.3-5.1,5.3h-15c-3.9,0-5.3-1.7-5.3-5.3V96.3c0-3.8,1.4-5.6,5.3-5.6h15   c3.6,0,5.1,1.8,5.1,5.6V115.9z M569.3,263.5c0,3.8-1.4,5.6-5.1,5.6h-15c-3.9,0-5.3-1.8-5.3-5.6V145.2c0-3.8,1.4-5.6,5.3-5.6h15   c3.6,0,5.1,1.8,5.1,5.6V263.5z"/>
                    <path d="M636.4,263.5c0,3.8-1.4,5.6-5.1,5.6h-15c-3.9,0-5.3-1.8-5.3-5.6V96.3c0-3.8,1.4-5.6,5.3-5.6h15   c3.6,0,5.1,1.8,5.1,5.6V263.5z"/>
                    <path d="M700.1,191h51.1V175c0-10.1-6.2-17-25.5-17c-19.6,0-25.5,6.9-25.5,17V191z M775.1,205.1c0,3.3-1.4,5.1-4.8,5.1   h-70.2v23.5c0,10.3,4.9,17,25.5,17c20.6,0,25.5-6.7,25.5-17v-2.9c0-3.6,1.5-5.3,5.3-5.3h13.2c3.6,0,5.3,1.7,5.3,5.3v4.4   c0,21.6-13,35.9-49.9,35.9c-37,0-49.9-14.4-49.9-35.9v-61.7c0-21.6,13.5-35.9,50.5-35.9c36.7,0,49.4,14.4,49.4,35.9V205.1z"/>
                    <path d="M903.1,269.2h-16.6c-3.7,0-5.9-2.9-7.5-5.2L850.2,222l-28.1,42.1c-1.5,2.3-3.5,5.2-7.5,5.2h-14.5   c-4.3,0-4.8-3.3-3.1-5.8l40.5-60.1l-39.2-57.8c-2-3.1-1.1-6.1,3.1-6.1H818c4,0,5.9,2.6,7.7,5.4l27.8,40.3l26.2-40.3   c1.8-2.9,4.1-5.5,7.8-5.5h14.8c4,0,4.8,3,2.8,6.1l-39.2,58.6l40.2,59.3C907.9,266,907.4,269.2,903.1,269.2z"/>
                    <path d="M958.2,115.9c0,3.6-1.4,5.3-5.1,5.3h-15c-3.9,0-5.3-1.7-5.3-5.3V96.3c0-3.8,1.4-5.6,5.3-5.6h15   c3.6,0,5.1,1.8,5.1,5.6V115.9z M958.2,263.5c0,3.8-1.4,5.6-5.1,5.6h-15c-3.9,0-5.3-1.8-5.3-5.6V145.2c0-3.8,1.4-5.6,5.3-5.6h15   c3.6,0,5.1,1.8,5.1,5.6V263.5z"/>
                    <path d="M1070.8,236.8v-21.7h-35.4c-12.7,0-16.7,5.5-16.7,15.4v5.2c0,9.8,5.4,14.1,18.3,14.1   C1052.5,249.9,1064.8,241.5,1070.8,236.8z M996.9,173c0-21.1,13.8-35.4,49.4-35.4c36.7,0,49.4,14.4,49.4,35.4v90.5   c0,3.8-1.4,5.6-5.1,5.6h-13.5c-3.9,0-5.1-1.8-5.3-5.6l-0.5-6.8c-7.7,6-21.3,14.4-40.6,14.4c-24.3,0-37-14.7-37-31.8v-12.4   c0-17.1,12.1-31.8,36.4-31.8h40.6v-19.6c0-10.3-5.2-17.2-24.8-17.2c-19.6,0-25.3,6.2-25.3,16.5v2.1c0,3.8-1.4,5.6-5.3,5.6H1002   c-3.6,0-5.1-1.8-5.1-5.6V173z"/>
                </g>

                <g className="subtitle">
                    <path d="M415.1,295.8h8.8v3.3h-8.8v7.1h-3.7V288h13.6v3.3h-9.9V295.8z"/>
                    <path d="M446,290.4c1.9,1.8,2.8,4.1,2.8,6.8c0,2.6-0.9,4.9-2.8,6.7c-1.9,1.8-4.1,2.8-6.8,2.8c-2.7,0-4.9-0.9-6.8-2.8    c-1.9-1.8-2.8-4.1-2.8-6.7c0-2.7,0.9-4.9,2.8-6.8c1.9-1.8,4.1-2.8,6.8-2.8C441.9,287.6,444.1,288.5,446,290.4z M443.4,301.4    c1.1-1.1,1.7-2.6,1.7-4.3s-0.6-3.2-1.7-4.3c-1.1-1.2-2.5-1.7-4.2-1.7s-3,0.6-4.2,1.7c-1.1,1.2-1.7,2.6-1.7,4.3s0.6,3.1,1.7,4.3    c1.1,1.1,2.5,1.7,4.2,1.7S442.2,302.6,443.4,301.4z"/>
                    <path d="M470.3,298.1c0,2.6-0.7,4.6-2.1,6.2c-1.4,1.6-3.3,2.4-5.7,2.4c-2.4,0-4.3-0.8-5.8-2.4    c-1.5-1.6-2.2-3.6-2.2-6.2v-10h3.7v10c0,1.5,0.4,2.7,1.2,3.6c0.8,0.9,1.8,1.4,3.1,1.4c1.2,0,2.2-0.5,3-1.4    c0.8-0.9,1.1-2.1,1.1-3.6v-10h3.7V298.1z"/>
                    <path d="M488.5,306.2l-6-6.7h-1.9v6.7h-3.7V288h8.7c2,0,3.5,0.5,4.7,1.6c1.2,1.1,1.8,2.4,1.8,4.2    c0,1.5-0.5,2.8-1.4,3.8s-2.2,1.6-3.8,1.9l6.1,6.8H488.5z M485.6,296.2c0.8,0,1.5-0.2,2.1-0.7c0.5-0.4,0.8-1,0.8-1.8    c0-0.7-0.3-1.3-0.8-1.7c-0.5-0.4-1.2-0.7-2.1-0.7h-5v4.8H485.6z"/>
                    <path d="M514.9,306.2h-3.1l-10.1-11.7v11.7h-3.7V288h3.1l10.1,12v-12h3.7V306.2z"/>
                    <path d="M521.7,306.2V288h3.7v18.2H521.7z"/>
                    <path d="M543.5,289c1.3,0.9,2.1,2.2,2.5,3.8l-3.7,0.8c-0.1-0.8-0.5-1.5-1.2-2c-0.7-0.5-1.5-0.8-2.4-0.8    c-0.9,0-1.6,0.2-2.1,0.7c-0.5,0.4-0.8,1-0.8,1.6c0,1,0.6,1.6,1.8,1.8l3.9,0.8c1.5,0.3,2.7,0.9,3.5,1.8c0.8,0.9,1.3,2,1.3,3.4    c0,1.8-0.7,3.1-2,4.2c-1.3,1-3.1,1.5-5.2,1.5c-2,0-3.7-0.5-5.1-1.4c-1.4-0.9-2.2-2.3-2.5-4l3.9-0.8c0.5,2,1.8,3,4.1,3    c1,0,1.8-0.2,2.4-0.7c0.6-0.4,0.8-1,0.8-1.7c0-0.5-0.2-1-0.5-1.3c-0.3-0.3-0.9-0.5-1.6-0.7l-3.9-0.7c-3.2-0.7-4.8-2.4-4.8-5.2    c0-1.6,0.6-3,1.9-4s2.9-1.5,5-1.5C540.6,287.6,542.2,288.1,543.5,289z"/>
                    <path d="M563.2,289c1.3,0.9,2.1,2.2,2.5,3.8l-3.7,0.8c-0.1-0.8-0.5-1.5-1.2-2c-0.7-0.5-1.5-0.8-2.4-0.8    c-0.9,0-1.6,0.2-2.1,0.7c-0.5,0.4-0.8,1-0.8,1.6c0,1,0.6,1.6,1.8,1.8l3.9,0.8c1.5,0.3,2.7,0.9,3.5,1.8c0.8,0.9,1.3,2,1.3,3.4    c0,1.8-0.7,3.1-2,4.2c-1.3,1-3.1,1.5-5.2,1.5c-2,0-3.7-0.5-5.1-1.4c-1.4-0.9-2.2-2.3-2.5-4l3.9-0.8c0.5,2,1.8,3,4.1,3    c1,0,1.8-0.2,2.4-0.7c0.6-0.4,0.8-1,0.8-1.7c0-0.5-0.2-1-0.5-1.3c-0.3-0.3-0.9-0.5-1.6-0.7l-3.9-0.7c-3.2-0.7-4.8-2.4-4.8-5.2    c0-1.6,0.6-3,1.9-4s2.9-1.5,5-1.5C560.4,287.6,561.9,288.1,563.2,289z"/>
                    <path d="M586,306.2h-13.8V288h13.8v3.3h-10.1v4.1h9.3v3.3h-9.3v4.1H586V306.2z"/>
                    <path d="M607.9,298.1c0,2.6-0.7,4.6-2.1,6.2c-1.4,1.6-3.3,2.4-5.7,2.4c-2.4,0-4.3-0.8-5.8-2.4    c-1.5-1.6-2.2-3.6-2.2-6.2v-10h3.7v10c0,1.5,0.4,2.7,1.2,3.6c0.8,0.9,1.8,1.4,3.1,1.4c1.2,0,2.2-0.5,3-1.4    c0.8-0.9,1.1-2.1,1.1-3.6v-10h3.7V298.1z"/>
                    <path d="M626.1,306.2l-6-6.7h-1.9v6.7h-3.7V288h8.7c2,0,3.5,0.5,4.7,1.6c1.2,1.1,1.8,2.4,1.8,4.2    c0,1.5-0.5,2.8-1.4,3.8s-2.2,1.6-3.8,1.9l6.1,6.8H626.1z M623.2,296.2c0.8,0,1.5-0.2,2.1-0.7c0.5-0.4,0.8-1,0.8-1.8    c0-0.7-0.3-1.3-0.8-1.7c-0.5-0.4-1.2-0.7-2.1-0.7h-5v4.8H623.2z"/>
                    <path d="M651.9,288c3,0,5.4,0.8,7.2,2.5c1.8,1.7,2.7,3.9,2.7,6.6c0,2.7-0.9,4.9-2.7,6.6c-1.8,1.7-4.2,2.5-7.2,2.5    h-6.7V288H651.9z M651.9,302.9c1.9,0,3.4-0.5,4.6-1.6c1.1-1,1.7-2.4,1.7-4.2s-0.6-3.2-1.7-4.2c-1.1-1-2.7-1.6-4.6-1.6h-3v11.5    H651.9z"/>
                    <path d="M681.6,306.2h-13.8V288h13.8v3.3h-10.1v4.1h9.3v3.3h-9.3v4.1h10.1V306.2z"/>
                    <path d="M708.9,289c1.3,0.9,2.1,2.2,2.5,3.8l-3.7,0.8c-0.1-0.8-0.5-1.5-1.2-2c-0.7-0.5-1.5-0.8-2.4-0.8    c-0.9,0-1.6,0.2-2.1,0.7s-0.8,1-0.8,1.6c0,1,0.6,1.6,1.8,1.8l3.9,0.8c1.5,0.3,2.7,0.9,3.5,1.8c0.8,0.9,1.3,2,1.3,3.4    c0,1.8-0.7,3.1-2,4.2c-1.3,1-3.1,1.5-5.2,1.5c-2,0-3.7-0.5-5.1-1.4c-1.4-0.9-2.2-2.3-2.5-4l3.9-0.8c0.5,2,1.8,3,4.1,3    c1,0,1.8-0.2,2.4-0.7c0.6-0.4,0.8-1,0.8-1.7c0-0.5-0.2-1-0.5-1.3c-0.3-0.3-0.9-0.5-1.6-0.7l-3.9-0.7c-3.2-0.7-4.8-2.4-4.8-5.2    c0-1.6,0.6-3,1.9-4s2.9-1.5,5-1.5C706,287.6,707.6,288.1,708.9,289z"/>
                    <path d="M733.2,290.4c1.9,1.8,2.8,4.1,2.8,6.8c0,2.6-0.9,4.9-2.8,6.7c-1.9,1.8-4.1,2.8-6.8,2.8    c-2.7,0-4.9-0.9-6.8-2.8c-1.9-1.8-2.8-4.1-2.8-6.7c0-2.7,0.9-4.9,2.8-6.8c1.9-1.8,4.1-2.8,6.8-2.8    C729.1,287.6,731.3,288.5,733.2,290.4z M730.5,301.4c1.1-1.1,1.7-2.6,1.7-4.3s-0.6-3.2-1.7-4.3c-1.1-1.2-2.5-1.7-4.2-1.7    c-1.6,0-3,0.6-4.2,1.7c-1.1,1.2-1.7,2.6-1.7,4.3s0.6,3.1,1.7,4.3c1.1,1.1,2.5,1.7,4.2,1.7C728,303.1,729.4,302.6,730.5,301.4z"/>
                    <path d="M754.9,306.2h-13.1V288h3.7v14.8h9.4V306.2z"/>
                    <path d="M776.2,298.1c0,2.6-0.7,4.6-2.1,6.2c-1.4,1.6-3.3,2.4-5.7,2.4c-2.4,0-4.3-0.8-5.8-2.4s-2.2-3.6-2.2-6.2v-10    h3.7v10c0,1.5,0.4,2.7,1.2,3.6c0.8,0.9,1.8,1.4,3.1,1.4c1.2,0,2.2-0.5,3-1.4c0.8-0.9,1.1-2.1,1.1-3.6v-10h3.7V298.1z"/>
                    <path d="M791.2,291.4v14.8h-3.7v-14.8h-6V288h15.8v3.3H791.2z"/>
                    <path d="M802.6,306.2V288h3.7v18.2H802.6z"/>
                    <path d="M828.6,290.4c1.9,1.8,2.8,4.1,2.8,6.8c0,2.6-0.9,4.9-2.8,6.7c-1.9,1.8-4.1,2.8-6.8,2.8    c-2.7,0-4.9-0.9-6.8-2.8c-1.9-1.8-2.8-4.1-2.8-6.7c0-2.7,0.9-4.9,2.8-6.8c1.9-1.8,4.1-2.8,6.8-2.8    C824.4,287.6,826.7,288.5,828.6,290.4z M825.9,301.4c1.1-1.1,1.7-2.6,1.7-4.3s-0.6-3.2-1.7-4.3c-1.1-1.2-2.5-1.7-4.2-1.7    c-1.6,0-3,0.6-4.2,1.7c-1.1,1.2-1.7,2.6-1.7,4.3s0.6,3.1,1.7,4.3c1.1,1.1,2.5,1.7,4.2,1.7C823.4,303.1,824.8,302.6,825.9,301.4z"/>
                    <path d="M854.1,306.2H851l-10.1-11.7v11.7h-3.7V288h3.1l10.1,12v-12h3.7V306.2z"/>
                    <path d="M872.2,289c1.3,0.9,2.1,2.2,2.5,3.8l-3.7,0.8c-0.1-0.8-0.5-1.5-1.2-2c-0.7-0.5-1.5-0.8-2.4-0.8    c-0.9,0-1.6,0.2-2.1,0.7c-0.5,0.4-0.8,1-0.8,1.6c0,1,0.6,1.6,1.8,1.8l3.9,0.8c1.5,0.3,2.7,0.9,3.5,1.8c0.8,0.9,1.3,2,1.3,3.4    c0,1.8-0.7,3.1-2,4.2c-1.3,1-3.1,1.5-5.2,1.5c-2,0-3.7-0.5-5.1-1.4c-1.4-0.9-2.2-2.3-2.5-4l3.9-0.8c0.5,2,1.8,3,4.1,3    c1,0,1.8-0.2,2.4-0.7c0.6-0.4,0.8-1,0.8-1.7c0-0.5-0.2-1-0.5-1.3c-0.3-0.3-0.9-0.5-1.6-0.7l-3.9-0.7c-3.2-0.7-4.8-2.4-4.8-5.2    c0-1.6,0.6-3,1.9-4c1.3-1,2.9-1.5,5-1.5C869.3,287.6,870.9,288.1,872.2,289z"/>
                    <path d="M907.5,306.2h-3.1l-10.1-11.7v11.7h-3.7V288h3.1l10.1,12v-12h3.7V306.2z"/>
                    <path d="M929.9,298.1c0,2.6-0.7,4.6-2.1,6.2c-1.4,1.6-3.3,2.4-5.7,2.4c-2.4,0-4.3-0.8-5.8-2.4s-2.2-3.6-2.2-6.2v-10    h3.7v10c0,1.5,0.4,2.7,1.2,3.6c0.8,0.9,1.8,1.4,3.1,1.4c1.2,0,2.2-0.5,3-1.4c0.8-0.9,1.1-2.1,1.1-3.6v-10h3.7V298.1z"/>
                    <path d="M956.7,306.2h-3.6v-9.9l-5.4,9.9h-2.2l-5.5-9.9v9.9h-3.6V288h3.2l6.9,12.6l6.9-12.6h3.2V306.2z"/>
                    <path d="M977.2,306.2h-13.8V288h13.8v3.3h-10.1v4.1h9.3v3.3h-9.3v4.1h10.1V306.2z M969.3,286.2l1.8-3.9h4.2l-3,3.9    H969.3z"/>
                    <path d="M995.1,306.2l-6-6.7h-1.9v6.7h-3.7V288h8.7c2,0,3.5,0.5,4.7,1.6c1.2,1.1,1.8,2.4,1.8,4.2    c0,1.5-0.5,2.8-1.4,3.8s-2.2,1.6-3.8,1.9l6.1,6.8H995.1z M992.2,296.2c0.8,0,1.5-0.2,2.1-0.7c0.5-0.4,0.8-1,0.8-1.8    c0-0.7-0.3-1.3-0.8-1.7c-0.5-0.4-1.2-0.7-2.1-0.7h-5v4.8H992.2z"/>
                    <path d="M1004.7,306.2V288h3.7v18.2H1004.7z"/>
                    <path d="M1032.6,301.1c-0.6,1.2-1.3,2.3-2.4,3.1l3.2,3.8l-2.8,2.6l-3.8-4.5c-0.9,0.3-1.9,0.4-2.9,0.4    c-2.7,0-4.9-0.9-6.8-2.8c-1.9-1.8-2.8-4.1-2.8-6.7c0-2.7,0.9-4.9,2.8-6.8c1.9-1.8,4.1-2.8,6.8-2.8s4.9,0.9,6.8,2.8    c1.9,1.8,2.8,4.1,2.8,6.8C1033.5,298.5,1033.2,299.9,1032.6,301.1z M1019.7,301.4c1.1,1.1,2.5,1.7,4.2,1.7c1.6,0,3-0.6,4.2-1.7    c1.1-1.1,1.7-2.6,1.7-4.3s-0.6-3.2-1.7-4.3c-1.1-1.2-2.5-1.7-4.2-1.7c-1.6,0-3,0.6-4.2,1.7c-1.1,1.2-1.7,2.6-1.7,4.3    S1018.6,300.3,1019.7,301.4z"/>
                    <path d="M1055,298.1c0,2.6-0.7,4.6-2.1,6.2s-3.3,2.4-5.7,2.4c-2.4,0-4.3-0.8-5.8-2.4c-1.5-1.6-2.2-3.6-2.2-6.2v-10    h3.7v10c0,1.5,0.4,2.7,1.2,3.6c0.8,0.9,1.8,1.4,3.1,1.4c1.2,0,2.2-0.5,3-1.4c0.8-0.9,1.1-2.1,1.1-3.6v-10h3.7V298.1z"/>
                    <path d="M1075.4,306.2h-13.8V288h13.8v3.3h-10.1v4.1h9.3v3.3h-9.3v4.1h10.1V306.2z"/>
                    <path d="M1093.1,289c1.3,0.9,2.1,2.2,2.5,3.8l-3.7,0.8c-0.1-0.8-0.5-1.5-1.2-2c-0.7-0.5-1.5-0.8-2.4-0.8    c-0.9,0-1.6,0.2-2.1,0.7s-0.8,1-0.8,1.6c0,1,0.6,1.6,1.8,1.8l3.9,0.8c1.5,0.3,2.7,0.9,3.5,1.8c0.8,0.9,1.3,2,1.3,3.4    c0,1.8-0.7,3.1-2,4.2c-1.3,1-3.1,1.5-5.2,1.5c-2,0-3.7-0.5-5.1-1.4c-1.4-0.9-2.2-2.3-2.5-4l3.9-0.8c0.5,2,1.8,3,4.1,3    c1,0,1.8-0.2,2.4-0.7c0.6-0.4,0.8-1,0.8-1.7c0-0.5-0.2-1-0.5-1.3c-0.3-0.3-0.9-0.5-1.6-0.7l-3.9-0.7c-3.2-0.7-4.8-2.4-4.8-5.2    c0-1.6,0.6-3,1.9-4s2.9-1.5,5-1.5C1090.2,287.6,1091.8,288.1,1093.1,289z"/>
                </g>
            </svg>
        </div>
    );
}

export default FirstLoader;